<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Уведомления по вашим заявлениям</div>
            </div>

            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th style="width: 5%">№</th>
                      <th style="width: 20%">Номер заявления</th>
                      <th style="width: 30%">Дата уведомления</th>
                      <th style="width: 40%">Тип уведомления</th>
                      <th style="width: 5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="notifications && !notifications.length">
                      <td colspan="4" class="align-center">Уведомления не найдены</td>
                    </tr>
                    <tr
                      v-else
                      v-for="(notification, index) in notifications"
                      :key="notification.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ notification.application?.id }}</td>
                      <td>{{ $filters.formatDate(notification.created_at) }}</td>
                      <td>{{ notification.type?.name }}</td>
                      <td class="align-center">
                        <a :href="notification.path" :download="notification.name">
                          <button class="link link--gray" type="button">
                            <SvgIcon name="eye-filled" :size="16" />
                          </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';
import { ExpertApplicationNotificationsRepository } from '@/repositories/expert/ExpertApplicationNotificationsRepository';

export default {
  name: 'Notifications.vue',
  components: { SvgIcon },
  data() {
    return {
      notifications: null,
    };
  },
  created() {
    ExpertApplicationNotificationsRepository.getForAuthenticatedExpert().then(
      (notifications) => (this.notifications = notifications)
    );
  },
};
</script>
