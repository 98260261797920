<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__header">
        <div class="title">{{ organization.fullName }}</div>
      </div>

      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <text-area
                    label="Полное наименование образовательной организации"
                    :model-value="organization.fullName"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <text-area
                    label="Сокращенное наименование образовательной организации"
                    :model-value="organization.shortName"
                    is-disabled
                  />
                </div>

                <cased-names v-bind="organization.casedName" />

                <div class="col col--full">
                  <input-text
                    label="Вид организационно-правовой формы"
                    :model-value="organization.legalForm"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <text-area
                    label="Учредители"
                    :model-value="organization.founders.map((founder) => founder.name).join(', ')"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <input-text
                    label="Специфика деятельности"
                    :model-value="organization.specificity"
                    is-disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <location-block v-bind="organization.address" />
        </div>

        <div v-if="!isExternal" class="main__block">
          <locations-block :locations="organization.locations" />
        </div>

        <div v-if="!isExternal" class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Банковские реквизиты организации</div>
            </div>

            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <input-text
                    label="Номер счета организации"
                    :model-value="organization.bank.accountNumber"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <input-text
                    label="Наименование банка"
                    :model-value="organization.bank.name"
                    is-disabled
                  />
                </div>
                <div class="col col--two-thirds">
                  <input-text
                    label="Корреспондентский счет банка"
                    :model-value="organization.bank.correspondentAccount"
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <input-text label="БИК банка" :model-value="organization.bank.bic" is-disabled />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <organization-details
            :tax-registration="organization.taxRegistration"
            :state-registration="organization.stateRegistration"
          />
        </div>

        <div class="main__block">
          <contacts-block v-bind="organization.contacts" />
        </div>

        <div class="main__block">
          <organization-head
            v-bind="organization.head"
            :phone-number="organization.head.phoneNumbers"
          />
        </div>

        <div v-if="!isExternal" class="main__block">
          <organization-accreditation
            title="Сведения об общественной аккредитации"
            v-model="accreditations.public"
          />
        </div>

        <div v-if="!isExternal" class="main__block">
          <organization-accreditation
            title="Сведения о профессионально-общественной аккредитации"
            v-model="accreditations.publicProfessional"
          />
        </div>

        <div v-if="!isExternal" class="main__block">
          <organization-accreditation
            title="Сведения о международной общественной аккредитации"
            v-model="accreditations.publicInternational"
          />
        </div>
      </div>

      <div class="main__footer main__footer--sticky">
        <div class="line line--jcsb">
          <div v-if="!isExternal" class="line__item">
            <custom-button label="Сохранить" @click="submit" />
          </div>
          <div class="line__item line__item--mla">
            <report-error-button />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { educationalOrganization } from '@/repositories';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import TextArea from '@/components/TextArea';
import CasedNames from '@/components/blocks/CasedNames';
import LocationBlock from '@/components/blocks/LocationBlock';
import LocationsBlock from '@/components/blocks/LocationsBlock';
import OrganizationDetails from '@/components/blocks/OrganizationDetails';
import ContactsBlock from '@/components/blocks/ContactsBlock';
import OrganizationHead from '@/components/blocks/OrganizationHead';
import ReportErrorButton from '@/components/ReportErrorButton';
import OrganizationAccreditation from '@/components/blocks/OrganizationAccreditation';
export default {
  name: 'GeneralInfo',
  components: {
    InputText,
    CustomButton,
    TextArea,
    CasedNames,
    LocationBlock,
    LocationsBlock,
    OrganizationDetails,
    ContactsBlock,
    OrganizationHead,
    ReportErrorButton,
    OrganizationAccreditation,
  },
  async created() {
    const organization = await educationalOrganization.getGeneralInfo();
    this.organization = organization;
    this.accreditations = organization.accreditations;
    this.$store.commit('eo/storeName', organization.fullName);
  },
  data() {
    return {
      organization: {
        founders: [],
        locations: [],
        bank: {},
        head: {},
      },
      accreditations: {},
    };
  },
  computed: {
    isExternal() {
      return this.$store.state.user.isExternal;
    },
  },
  methods: {
    async submit() {
      try {
        await educationalOrganization.putAccreditations(this.accreditations);
      } catch {
        alert('Не удалось сохранить аккредитации. Попробуйте, пожалуйста, позже!');
      }
    },
  },
};
</script>
