<template>
  <div class="main-wrap">
    <div class="header">
      <div class="header__wrap">
        <div class="header__logo">
          <div class="logo">
            <img
              src="~@/assets/images/logo-visual.svg"
              width="42"
              height="36"
              alt="Логотип ИС ГА"
              class="logo__img"
            />
            <img
              src="~@/assets/images/logo-text.svg"
              width="81"
              height="19"
              alt="ИС ГА"
              class="logo__text"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="wrap">
      <main class="main">
        <div class="main__wrap main__wrap--single">
          <div class="main__header">
            <div class="main__title">
              <div class="title">Реестр экспертов и экспертных организаций</div>
            </div>
          </div>
          <div class="main__tabs">
            <div class="tabs tabs--2 tabs--m">
              <ul class="tabs__list">
                <li class="tabs__item">
                  <a
                    href="#"
                    @click.prevent="show = EXPERTS"
                    class="tabs__link"
                    :class="{ ' tabs__link--active': show === EXPERTS }"
                  >
                    Эксперты
                  </a>
                </li>
                <li class="tabs__item">
                  <a
                    href="#"
                    @click.prevent="show = ORGANIZATIONS"
                    class="tabs__link"
                    :class="{ ' tabs__link--active': show === ORGANIZATIONS }"
                  >
                    Экспертные организации
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="main__body">
            <div class="main__block">
              <div class="block">
                <div class="block__top">
                  <form
                    @submit.prevent="
                      pagination.reset();
                      fetchExperts();
                    "
                    class="row row--aife"
                  >
                    <div class="col col--half">
                      <CustomSelect
                        label="УГСН"
                        placeholder="Выберите УГСН"
                        :options="enlargedSpecialityGroupOptions"
                        v-model="constrains.egs_id"
                        :is-disabled="!!constrains.speciality_id"
                      />
                    </div>
                    <div class="col col--half">
                      <CustomSelect
                        label="Уровень образования"
                        placeholder="Выберите уровень образования"
                        :options="educationLevelOptions"
                        v-model="constrains.education_level_id"
                      />
                    </div>
                    <div class="col col--full">
                      <CustomSelect
                        label="Профессия, специальность, направление подготовки"
                        placeholder="Выберите специальность"
                        :options="specialtyOptions"
                        v-model="constrains.speciality_id"
                        :is-disabled="!!constrains.egs_id"
                      />
                    </div>
                    <div class="col col--half">
                      <InputText
                        :placeholder="
                          show === EXPERTS ? 'ФИО эксперта' : 'Наименование организации'
                        "
                        v-model="constrains.name"
                      />
                    </div>
                    <div class="col col--fourth">
                      <CustomButton
                        label="Сбросить"
                        :type="4"
                        is-expandable
                        @click="resetConstrains"
                      />
                    </div>
                    <div class="col col--fourth">
                      <CustomButton label="Искать" button-type="submit" is-expandable />
                    </div>
                  </form>
                </div>
                <div class="block__main">
                  <div class="tbl-wrap">
                    <table class="tbl">
                      <thead>
                        <tr>
                          <th v-if="show === EXPERTS" class="align-center" style="width: 85%">
                            ФИО
                          </th>
                          <th v-if="show === ORGANIZATIONS" class="align-center" style="width: 70%">
                            Наименование организации
                          </th>
                          <th v-if="show === ORGANIZATIONS" class="align-center" style="width: 15%">
                            ОГРН
                          </th>
                          <th class="align-center" style="width: 15%">Окончание полномочий</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="expert in experts"
                          :key="expert.id"
                          @click="
                            $router.push({
                              name: 'registries.experts.show',
                              params: { id: expert.id },
                            })
                          "
                        >
                          <td>{{ expert.full_name }}</td>
                          <td v-if="show === ORGANIZATIONS">{{ expert.ogrn }}</td>
                          <td>
                            {{
                              expert.accredited_until == null
                                ? 'Бессрочно'
                                : $filters.formatDate(expert.accredited_until)
                            }}
                          </td>
                        </tr>
                        <tr v-if="experts && experts.length === 0">
                          <td v-if="show === EXPERTS" colspan="2">Эксперты не найдены</td>
                          <td v-if="show === ORGANIZATIONS" colspan="3">
                            Экспертные организации не найдены
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__footer main__footer--sticky">
            <div class="line line--jcsb">
              <div class="line__item"></div>
              <div class="line__item">
                <div class="pager">
                  <ul class="pager__list">
                    <li v-for="page in pagination.pages" :key="page" class="pager__item">
                      <a
                        @click.prevent="
                          typeof page === 'number' ? (pagination.page.value = page) : false
                        "
                        href="#"
                        class="pager__link"
                        :class="{ 'pager__link--active': page === pagination.page.value }"
                        >{{ page }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import axios from '@/api/axios';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import CustomSelect from '@/components/CustomSelect';

const ORGANIZATIONS = 'organizations';
const EXPERTS = 'experts';
const show = ref(EXPERTS);
const pagination = {
  page: ref(1),
  totalPages: null,
  reset() {
    this.page.value = 1;
    this.totalPages = null;
  },
  get pages() {
    /** @var {(string|number)[]} pages */
    const pages = [1];

    if (this.page.value > 3) {
      pages.push('…');
    }

    pages.push(...[...Array(5).keys()].map((key) => key + this.page.value - 2));
    if (this.totalPages - pages[pages.length - 1] > 2) {
      pages.push('…');
    }
    pages.push(this.totalPages);
    return pages
      .filter((page) => typeof page === 'string' || (page > 0 && page <= this.totalPages))
      .reduce((result, page) => {
        if (typeof page === 'string' || result.indexOf(page) === -1) {
          result.push(page);
        }
        return result;
      }, []);
  },
};

const constrains = reactive({
  name: null,
  /* Пустые строки необходимы для корректной работы пункта "Не выбрано" в селекте. Null он не поддерживает в качестве значения */
  egs_id: '',
  speciality_id: '',
  education_level_id: '',
});

function resetConstrains() {
  Object.assign(constrains, {
    name: null,
    egs_id: '',
    speciality_id: '',
    education_level_id: '',
  });
}

const enlargedSpecialityGroups = ref([]);
const enlargedSpecialityGroupOptions = computed(() => {
  const any = { value: '', text: 'Не выбрано' };
  const options = enlargedSpecialityGroups.value.map((group) => ({
    value: group.id,
    text: (group.code ? group.code + ' ' : '') + group.name,
  }));
  return [any, ...options];
});
fetchEnlargedSpecialityGroups().then((groups) => (enlargedSpecialityGroups.value = groups));

const educationLevels = ref([]);
const educationLevelOptions = computed(() => {
  const any = { value: '', text: 'Не выбрано' };
  const options = educationLevels.value.map((level) => ({
    value: level.id,
    text: level.name,
  }));
  return [any, ...options];
});
fetchEducationLevels().then((levels) => (educationLevels.value = levels));

const specialities = ref([]);
const specialtyOptions = computed(() => {
  const any = { value: '', text: 'Не выбрано' };
  const options = specialities.value.map((speciality) => ({
    value: speciality.id,
    text: (speciality.code ? speciality.code + ' ' : '') + speciality.name,
  }));
  return [any, ...options];
});
fetchSpecialities().then((result) => (specialities.value = result));

const experts = ref(null);

const fetchExperts = makeFetchExperts();
fetchExperts();
watch([show, pagination.page], fetchExperts);
watch(show, () => {
  experts.value = null;
  pagination.reset();
  constrains.name = null;
  constrains.egs_id = '';
  constrains.speciality_id = '';
  constrains.education_level_id = '';
});

/*
 * Создает функцию, которая запрашивает экспертов
 * и при этом обходит "гонку" запросов, возвращая данные только последнего отправленного запроса.
 */
function makeFetchExperts() {
  let latestRequest = null;
  return async function () {
    const request = (latestRequest = axios.get('experts', {
      params: {
        organizations: +(show.value === ORGANIZATIONS),
        page: pagination.page.value,
        name: constrains.name,
        /* Чтобы не добавлялись пустые строки к запросу, проверяем на истинность и меняем на null */
        egs_id: constrains.egs_id ? constrains.egs_id : null,
        speciality_id: constrains.speciality_id ? constrains.speciality_id : null,
        education_level_id: constrains.education_level_id ? constrains.education_level_id : null,
      },
    }));

    try {
      const response = await request;
      if (request !== latestRequest) {
        return;
      }

      experts.value = response.data.data;
      pagination.totalPages = response.data.meta.last_page;
    } catch (error) {
      window.alert('Не удалось получить данные. Попробуйте, пожалуйста, позже!');
      throw error;
    }
  };
}

async function fetchEnlargedSpecialityGroups() {
  const response = await axios.get('experts/enlarged-speciality-groups');
  return response.data.data;
}

async function fetchEducationLevels() {
  const response = await axios.get('experts/education-levels');
  return response.data.data;
}

async function fetchSpecialities() {
  const response = await axios.get('experts/specialities');
  return response.data.data;
}
</script>
